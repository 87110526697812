<!--<p-inputSwitch [(ngModel)]="signupForm.isOrg">Зарегистрироваться как организация</p-inputSwitch>-->
<!--<input type="text" *ngIf="signupForm.isOrg" pInputText placeholder="НАЗВАНИЕ ОРГАНИЗАЦИИ" [(ngModel)]="signupForm.orgName">-->
<!--<input type="text" pInputText placeholder="ФАМИЛИЯ" [(ngModel)]="signupForm.userLastName">-->
<!--<input type="text" pInputText placeholder="ИМЯ И ОТЧЕСТВО" [(ngModel)]="signupForm.userName">-->
<!--<input type="text" [(ngModel)]="signupForm.phone" placeholder="ТЕЛЕФОН"-->
<!--       mask="+0 (000) 000-00-00"-->
<!--&gt;-->
<form [formGroup]="signupForm">
  <input formControlName="email" placeholder="E-MAIL" type="email">
  <input formControlName="password" placeholder="Пароль" type="password">
  <input formControlName="repeatPassword" placeholder="Повторите пароль" type="password">
  <div>
    <input type="checkbox" formControlName="agreement">
    <span>Я согласен с условиями использования и политикой конфиденциальности</span>
  </div>
  <div *ngIf="signupForm.controls.email.touched && signupForm.controls.email.invalid">
    <small *ngIf="signupForm.controls.email.errors?.['required']">Email обязателен</small>
    <small *ngIf="signupForm.controls.email.errors?.['email']">Неверный формат email</small>
  </div>

  <div *ngIf="signupForm.controls.password.touched && signupForm.controls.password.invalid">
    <small *ngIf="signupForm.controls.password.errors?.['required']">Пароль обязателен</small>
    <small *ngIf="signupForm.controls.password.errors?.['minlength']">Пароль должен содержать минимум 8 символов</small>
  </div>

  <div *ngIf="signupForm.controls.repeatPassword.touched && signupForm.controls.repeatPassword.invalid">
    <small *ngIf="signupForm.controls.repeatPassword.errors?.['required']">Повторите пароль</small>
    <small *ngIf="signupForm.controls.repeatPassword.errors?.['minlength']">Пароль должен содержать минимум 8 символов</small>
  </div>

  <div *ngIf="signupForm.errors?.['passwordsDoNotMatch']">
    <small>Пароли не совпадают</small>
  </div>

  <div *ngIf="signupForm.controls.agreement.touched && signupForm.controls.agreement.invalid">
    <small *ngIf="signupForm.controls.agreement.errors?.['required']">Вы должны согласиться с условиями</small>
  </div>
  <input class="submit" type="submit" [disabled]="!signupForm.valid" value="ЗАРЕГИСТРИРОВАТЬСЯ" (click)="signup()">
</form>

