import { createAction, props } from '@ngrx/store';
import {IMessage} from "../../models/message";

export enum MessageActionTypes {
    OPEN_MESSAGE = '[Message] Open message',
    CLOSE_MESSAGE = '[Message] Close message',
}

export const openMessage = createAction(
    MessageActionTypes.OPEN_MESSAGE,
    props<IMessage>()
);

export const closeMessage = createAction(
    MessageActionTypes.CLOSE_MESSAGE
);
