import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private config: PrimeNGConfig, private translateService: TranslateService) {}
  ngOnInit() {
    this.translate('ru');
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('ru').subscribe(res => this.config.setTranslation(res));
  }
}

