import {Injectable} from '@angular/core';

import {BaseService} from './base.service';
import {SigninForm} from '../login-screen/log-in/log-in.component';
import {SignupForm} from '../login-screen/sign-up/sign-up.component';
import {Observable} from 'rxjs';

export interface TokenResponse {
	token: string
}

@Injectable({
	providedIn: 'root'
})
export class AuthService extends BaseService {

	login(signinForm: SigninForm): Observable<TokenResponse> {
		return this.post<TokenResponse>('/auth/signin', JSON.stringify(signinForm));
	}

	signup(signupForm: SignupForm): Observable<any> {
		return this.post<any>('/auth/signup', JSON.stringify(signupForm))
	}

	token(): Observable<TokenResponse> {
		return this.get<TokenResponse>('/auth/token', false);
	}

	get_code(checkCodeForm: any): Observable<any> {
	// 	const endpointUrl = this.RS + '/resetPassword';
	// 	const dataStr = JSON.stringify(checkCodeForm);
	// 	return this.http.post(endpointUrl, dataStr, {headers: this.getHeaders()});
	// 	// let _resourceUrl = this.RS + "/resetPassword";
	// 	// let ret_subj = new AsyncSubject() as AsyncSubject<string>;
	// 	// let data_str = JSON.stringify(checkCodeForm);
	// 	//
	// 	// this._http.post(_resourceUrl, data_str, {withCredentials: true, headers: SessionService.headers}).pipe(
	// 	//     map((res: Response) => res)).subscribe(raw => {
	// 	//     let data = JSON.parse(JSON.stringify(raw));
	// 	//     if (data.userId){
	// 	//         this._hubService.getProperty("modal-window").showMessage("На ваш номер телефон выслан код",  null);
	// 	//         ret_subj.next(data.userId);
	// 	//     }
	// 	//     ret_subj.complete();
	// 	// }, err => this.handle_errors(err));
	// 	//
		return new Observable();
	}

	check_code(checkCodeForm: any): Observable<any> {
	// 	const endpointUrl = this.RS + '/checkCode';
	// 	const dataStr = JSON.stringify(checkCodeForm);
	// 	return this.http.post(endpointUrl, dataStr, {headers: this.getHeaders()});
	// 	// let _resourceUrl = this.RS + "/checkCode";
	// 	// let ret_subj = new AsyncSubject() as AsyncSubject<boolean>;
	// 	// let data_str = JSON.stringify(checkCodeForm);
	// 	//
	// 	// this._http.post(_resourceUrl, data_str, {headers: SessionService.headers}).pipe(
	// 	//     map((res: Response) => res)).subscribe(raw => {
	// 	//         console.log(raw, JSON.parse(JSON.stringify(raw)))
	// 	//         let data = JSON.parse(JSON.stringify(raw));
	// 	//         if (data.result == "OK"){
	// 	//             ret_subj.next(true);
	// 	//         }else
	// 	//             this._hubService.getProperty("modal-window").showMessage("Системная ошибка! Обратитесь в службу поддержки",  null);
	// 	//         ret_subj.complete();
	// 	//     }, err => this.handle_errors(err)
	// 	// );
	// 	//
		return new Observable();

	}

	logout(): void {
		// const _endpointUrl = this.RS + "/logout";
		//
		// this._http.post(_endpointUrl, "", {withCredentials: true}).pipe(
		//     map((res: Response) => res))
		//     .subscribe(
		//         () => {
		//             this.dataStore.authorized = false;
		//             this._authorized.next(this.dataStore.authorized);
		//
		//             this.dataStore.msg = "logged out";
		//             this._msg.next(this.dataStore.msg);
		//         },
		//         err => this.handle_errors(err)
		//     );

	}

	private setSession(authResult: any): void {
		// const expiresAt = DateTime.local().add(3600,'second');

		localStorage.setItem('idToken', authResult.token);
	}
}
