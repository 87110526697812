import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthService} from '../../service/auth.service';
import {signup} from '../../store/actions/auth.actions';
import {AppState} from '../../store/states/app.states';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";

export interface ISignupForm {
  email: FormControl<string>,
  password: FormControl<string>,
  repeatPassword: FormControl<string>,
  agreement: FormControl<boolean>;
  // isOrg: boolean;
  // userName: string | null;
  // userLastName: string | null;
  // email: string | null;
  // phone: string | null;
  // orgName: string | null;
}

export interface SignupForm {
  email: string,
  password: string,
  repeatPassword: string,
  agreement: boolean;
}

// export class  SignupForm implements ISignupForm {
//   isOrg = false;
//   userName = null;
//   userLastName = null;
//   email = null;
//   phone = null;
//   orgName = null;
//   password = null
//   agreement = false;
// }

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent {
  signupForm: FormGroup<ISignupForm> = new FormGroup<ISignupForm>({
    email: new FormControl<string>('', {validators: [Validators.email, Validators.required], nonNullable: true}),
    password: new FormControl<string>('', {validators: [Validators.minLength(8), Validators.required], nonNullable: true}),
    repeatPassword: new FormControl<string>('', {validators: [Validators.minLength(8), Validators.required], nonNullable: true}),
    agreement: new FormControl<boolean>(false, {validators: [Validators.requiredTrue], nonNullable: true}),
  }, {
    validators: (control) => {
      const password = control.get('password')?.value;
      const repeatPassword = control.get('repeatPassword')?.value;

      return password && repeatPassword && password !== repeatPassword
        ? { passwordsDoNotMatch: true }
        : null;
    }
  });

  constructor(private sessionService: AuthService,
              private store: Store<AppState>) {
  }

  signup(): void {
    if (this.signupForm.valid){
      this.store.dispatch(signup(this.signupForm.getRawValue()));
    }
    // if (!this.signupForm.email || !this.signupForm.phone) {
    //   // this.hubService.getProperty("modal-window").showMessage("Не указаны контактные данные", null);
    //   return;
    // }

    // if (this.repeatPassword != this.signupForm.password){
    //   this.disabled = true;
    // }

    // if (!this.signupForm.userName) {
    //     // this.hubService.getProperty("modal-window").showMessage("Не указано Имя Отчество", null);
    //     return;
    // }
    //
    // if (!this.signupForm.userLastName) {
    //     // this.hubService.getProperty("modal-window").showMessage("Не указана Фамилия", null);
    //     return;
    // }

    // if (!this.signupForm.agreement) {
    //   // this.hubService.getProperty("modal-window").showMessage("Необходимо принять соглашение", null);
    //   return;
    // }



    // this.sessionService.registering(this.signupForm).subscribe((res: any) => {
    //     if (res) {
    //         // this.hubService.getProperty("modal-window").showMessage("Регистрация прошла успешно! Для подтвержения номера телефона и
    //         // задания пароля используйте код, отправленный Вам в SMS сообщении.", null);
    //         // this.checkCodeForm.userId = res;
    //         // this.checkCodeForm.phone = this.signupForm.phone;
    //         // this.typeWindow = 2;
    //     }
    // });
  }

}
