export enum MessageType {
  INFO, WARNING, ERROR
}

export interface IMessage {
  text?: string;
  messageType: MessageType;
}

export class Message implements IMessage{
  text?: string;
  messageType: MessageType;

  constructor(text: string, type: MessageType) {
    this.text = text;
    this.messageType = type;
  }

}
