<form [formGroup]="signinForm">
  <input formControlName="email" placeholder="E-MAIL" type="email">
  <input formControlName="password" placeholder="Пароль" type="password">
</form>

<div *ngIf="signinForm.controls.email.touched && signinForm.controls.email.invalid">
  <small *ngIf="signinForm.controls.email.errors?.['required']">Email обязателен</small>
  <small *ngIf="signinForm.controls.email.errors?.['email']">Неверный формат email</small>
</div>

<div *ngIf="signinForm.controls.password.touched && signinForm.controls.password.invalid">
  <small *ngIf="signinForm.controls.password.errors?.['required']">Пароль обязателен</small>
</div>

<div class="link_button" routerLink="/reset">Забыли пароль?</div>
<input class="submit" type="submit" value="ВОЙТИ В СИСТЕМУ" (click)="login()"
       style="margin-top: 35px">
